<template>
  <div class="kb">
    <header class="kb__header">
      <h2 class="kb__heading">Knowledge Bank</h2>
      <div class="kb__description">
        Want to become an expert and learn more about footwear production? We have gathered knowledge from industry
        experts on topics ranging from footwear types to the manufacturing process!
      </div>
    </header>
    <section class="kb__main">
      <div class="kb__sidebar">
        <div class="kb__menu">
          <div class="kb__menu-category" v-for="category in articlesMenu">
            <div class="kb__menu-category-title">{{ category.title }}</div>
            <div class="kb__menu-articles">
              <div class="kb__menu-article" v-for="article in category.articles" :key="article.id">
                <router-link
                  class="kb__menu-article-link"
                  :to="{ name: 'article', params: { category: category.slug, slug: article.slug } }"
                >
                  {{ article.title }}
                </router-link>
                <div class="kb__menu-subarticle" v-for="subarticle in article.subArticles" :key="subarticle.id">
                  <router-link
                    class="kb__menu-subarticle-link"
                    :to="{ name: 'article', params: { category: category.slug, slug: subarticle.slug } }"
                  >
                    {{ subarticle.title }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kb__featured">
        <div class="kb__featured-category" v-for="category in featuredArticles">
          <span class="kb__featured-label">{{ category.title }}</span>
          <div class="kb__featured-articles">
            <router-link
              class="kb__featured-article"
              v-for="article in category.articles.slice(0, 3)"
              :to="{ name: 'article', params: { category: category.slug, slug: article.slug } }"
              :key="article.id"
            >
              <div
                class="kb__featured-article-img"
                :style="{ backgroundImage: `url(${$blobUrl}/images/${article.previewImg})` }"
              >
                <span class="kb__featured-article-rm">Read more</span>
              </div>
              <strong class="kb__featured-article-title">
                {{ article.title }}
              </strong>
              <p class="kb__featured-article-description">
                {{ article.description }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  load({ store, route }) {
    // console.log(555);
    store.head.title = 'FindSourcing – Knowledge Bank';

    return store.knowledgebank.loadFeatured();
  },
  computed: {
    articlesMenu() {
      return this.$store.categories.all || [];
    },
    featuredArticles() {
      return this.$store.knowledgebank.featured || [];
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.kb {
  max-width: math.div(126rem, 1.6);
  padding: math.div(2rem, 1.6) math.div(1.5rem, 1.6);
  margin: 0 auto;
  &__header {
    text-align: center;
  }
  &__heading {
    margin: math.div(2rem, 1.6) 0;
    font-weight: normal;
    font-size: math.div(2.4rem, 1.6);
    line-height: 1;
    @include tablet-min {
      font-size: math.div(3.6rem, 1.6);
    }
    @include tablet-landscape-min {
      font-size: math.div(4.2rem, 1.6);
    }
    @include desktop-min {
      font-size: math.div(5rem, 1.6);
    }
  }
  &__description {
    max-width: math.div(93rem, 1.6);
    margin: 0 auto;
    font-style: italic;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.5px;
    @include tablet-min {
      font-size: math.div(1.8rem, 1.6);
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    margin-top: math.div(5rem, 1.6);
  }
  &__sidebar {
    display: none;
    @include tablet-min {
      display: block;
      width: math.div(23.5rem, 1.6);
      padding-right: math.div(3rem, 1.6);
      flex-shrink: 0;
    }
    @include tablet-landscape-min {
      width: math.div(27.5rem, 1.6);
    }
  }
  &__menu {
    &-category {
      &-title {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.8px;
        font-size: math.div(1.4rem, 1.6);
        line-height: math.div(3rem, 1.6);
      }
    }
    &-articles {
      padding: 0 0 math.div(2rem, 1.6) math.div(1rem, 1.6);
    }
    &-article {
      margin-top: math.div(2rem, 1.6);
      &-link {
        letter-spacing: 0.8px;
        font-weight: bold;
        font-size: math.div(1.4rem, 1.6);
      }
    }
    &-subarticle {
      margin-top: math.div(1rem, 1.6);
      &-link {
        letter-spacing: 0.8px;
        font-size: math.div(1.4rem, 1.6);
      }
    }
    a {
      color: $c-dark;
      transition: color 0.5s ease;
      &:hover {
        color: $c-blue;
      }
    }
  }
  &__featured {
    width: 100%;
    &-category {
      &:not(:first-child) {
        margin-top: math.div(9rem, 1.6);
      }
    }
    &-label {
      display: inline-block;
      font-size: math.div(1.1rem, 1.6);
      font-weight: bold;
      letter-spacing: 0.7px;
      line-height: math.div(3rem, 1.6);
      padding: 0 math.div(1.8rem, 1.6);
      background: $c-dark;
      color: $c-light;
      text-transform: uppercase;
    }
    &-articles {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      margin-top: math.div(2rem, 1.6);
    }
    &-article {
      width: 100%;
      color: $c-dark;
      border: 0;
      font-size: math.div(1.4rem, 1.6);
      line-height: 1.45;
      letter-spacing: 0.8px;
      &:not(:first-child) {
        margin-top: math.div(3.5rem, 1.6);
      }
      @include mobile-ls-min {
        width: calc(50% - #{math.div(1.5rem, 1.6)});
        &:not(:first-child) {
          margin-top: 0;
        }
        &:nth-child(2n) {
          margin-left: math.div(3rem, 1.6);
        }
        &:nth-child(n + 3) {
          margin-top: math.div(3.5rem, 1.6);
        }
      }
      @include desktop-min {
        width: calc(33.3333333% - #{math.div(2rem, 1.6)});
        margin-top: 0;
        &:nth-child(2n) {
          margin-left: 0;
        }
        &:nth-child(n + 3) {
          margin-top: 0;
        }
        &:not(:nth-child(3n + 1)) {
          margin-left: math.div(3rem, 1.6);
        }
        &:nth-child(n + 4) {
          margin-top: math.div(3.5rem, 1.6);
        }
      }
      &-img {
        position: relative;
        margin-bottom: math.div(0.5rem, 1.6);
        background-size: cover;
        background-position: 50% 50%;
        background-color: $c-light-gray;
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 73.3333333%;
        }
      }
      &-rm {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: math.div(1rem, 1.6);
        right: math.div(1rem, 1.6);
        color: $c-light;
        line-height: math.div(2.4rem, 1.6);
        padding: 0 math.div(1rem, 1.6);
        background: $c-dark;
        font-size: math.div(1.1rem, 1.6);
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.7px;
        opacity: 0;
        transition: opacity 0.5s ease;
        &:after {
          content: '';
          display: block;
          width: math.div(0.4rem, 1.6);
          height: math.div(0.4rem, 1.6);
          margin-left: math.div(0.5rem, 1.6);
          border-top: 2px solid $c-light;
          border-right: 2px solid $c-light;
          transform: rotate(45deg);
        }
        .kb__featured-article:hover & {
          opacity: 1;
        }
      }
      &-title {
        display: block;
        padding: math.div(0.5rem, 1.6) 0;
      }
      &-description {
        margin: 0;
      }
    }
  }
}
</style>
