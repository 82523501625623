var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kb" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "kb__main" }, [
      _c("div", { staticClass: "kb__sidebar" }, [
        _c(
          "div",
          { staticClass: "kb__menu" },
          _vm._l(_vm.articlesMenu, function (category) {
            return _c("div", { staticClass: "kb__menu-category" }, [
              _c("div", { staticClass: "kb__menu-category-title" }, [
                _vm._v(_vm._s(category.title)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "kb__menu-articles" },
                _vm._l(category.articles, function (article) {
                  return _c(
                    "div",
                    { key: article.id, staticClass: "kb__menu-article" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "kb__menu-article-link",
                          attrs: {
                            to: {
                              name: "article",
                              params: {
                                category: category.slug,
                                slug: article.slug,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(article.title) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(article.subArticles, function (subarticle) {
                        return _c(
                          "div",
                          {
                            key: subarticle.id,
                            staticClass: "kb__menu-subarticle",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kb__menu-subarticle-link",
                                attrs: {
                                  to: {
                                    name: "article",
                                    params: {
                                      category: category.slug,
                                      slug: subarticle.slug,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(subarticle.title) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "kb__featured" },
        _vm._l(_vm.featuredArticles, function (category) {
          return _c("div", { staticClass: "kb__featured-category" }, [
            _c("span", { staticClass: "kb__featured-label" }, [
              _vm._v(_vm._s(category.title)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "kb__featured-articles" },
              _vm._l(category.articles.slice(0, 3), function (article) {
                return _c(
                  "router-link",
                  {
                    key: article.id,
                    staticClass: "kb__featured-article",
                    attrs: {
                      to: {
                        name: "article",
                        params: { category: category.slug, slug: article.slug },
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "kb__featured-article-img",
                        style: {
                          backgroundImage:
                            "url(" +
                            _vm.$blobUrl +
                            "/images/" +
                            article.previewImg +
                            ")",
                        },
                      },
                      [
                        _c("span", { staticClass: "kb__featured-article-rm" }, [
                          _vm._v("Read more"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "strong",
                      { staticClass: "kb__featured-article-title" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(article.title) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "kb__featured-article-description" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(article.description) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "kb__header" }, [
      _c("h2", { staticClass: "kb__heading" }, [_vm._v("Knowledge Bank")]),
      _vm._v(" "),
      _c("div", { staticClass: "kb__description" }, [
        _vm._v(
          "\n      Want to become an expert and learn more about footwear production? We have gathered knowledge from industry\n      experts on topics ranging from footwear types to the manufacturing process!\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }